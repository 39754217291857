import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSignatureInfo, saveSignatureInfo } from 'core/services/signature/signatureService';
import { useSignatureInfo } from 'components/hooks/signature/useSignature';
import { Loading } from 'components/common/loading/Loading';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { TabsSignature } from './TabsSignature';
import { InfoPartSignature } from './InfoPartSignature';
import { BannerPartSignature } from './BannerPartSignature';
import { getBanners } from 'core/services/banner/bannerService';
import { useBanners } from 'components/hooks/banner/useBanner';
import { BannerResponse, BannerSectionsResponse } from 'models/banner';
import { PreviewSignature } from './PreviewSignature';
import classNames from 'classnames';
import { SignatureInfoResponse } from 'models/signature';
import { getSGConnect } from 'core/services/common/sgConnect';
import { useTranslation } from 'react-i18next';
import { AccountSignatureSkeleton } from './AccountSignatureSkeleton';
import { TFunction } from 'i18next';

export interface ISignatureForm {
    firstName: string,
    lastName: string,
    pronouns?: string,
    emailAddress: string,
    jobTitle?: string,
    jobTitlePart2?: string,
    department?: string,
    useDefaultDisclaimer: boolean,
    disclaimer?: string,
    addressFormat: 'Europe' | 'UK_US',
    street?: string,
    zipCode?: string,
    city?: string,
    country?: string,
    directPhone?: string,
    mobilePhone?: string,
    website?: string,
    freeText?: string,
    includeInOutlook: boolean,
    bannerId: number,
    templateUrl?: string,
    bannerLink?: string,
    pictureUrl?: string,
    templateRessourceKey?: string,
}

const schema = (bannerSections: BannerSectionsResponse, translate: TFunction) => yup
    .object({
        firstName: yup.string().required(translate('signature:form:firstNameRequired')).max(100, translate('signature:form:firstNameMaxLength')),
        lastName: yup.string().required(translate('signature:form:lastNameRequired')).max(100, translate('signature:form:lastNameMaxLength')),
        pronouns: yup.string().max(100),
        emailAddress: yup.string().required(translate('signature:form:emailAddressRequired')).max(100),
        jobTitle: yup.string().max(100),
        jobTitlePart2: yup.string().max(100),
        department: yup.string().max(100),
        useDefaultDisclaimer: yup.boolean().required(),
        disclaimer: yup.string(),
        addressFormat: yup.string().oneOf(['Europe', 'UK_US']).required(translate('signature:form:addressFormatRequired')),
        street: yup.string().max(200),
        zipCode: yup.string().max(50),
        city: yup.string().max(100),
        country: yup.string(),
        directPhone: yup.string().max(50),
        mobilePhone: yup.string().max(50),
        website: yup.string().max(200),
        freeText: yup.string().max(1000),
        includeInOutlook: yup.boolean().required(),
        bannerId: yup.number().required().test('valid banner id', translate('signature:pleaseChooseBanner'), value => {
            const allBanners = bannerSections.reduce((prev, curr) => [...prev, ...(curr.Banners || [])], [] as BannerResponse[]);
            return !!allBanners.find(x => x.BannerId === value);
        }),
        templateUrl: yup.string(),
        bannerLink: yup.string(),
        pictureUrl: yup.string(),
        templateRessourceKey: yup.string(),
    });

type EditSignatureProps = {
    onCancel: () => void;
    onSave: () => void;
}

type TabType = 'info' | 'banner';

export const EditSignature: FC<EditSignatureProps> = ({ onCancel, onSave }) => {
    const dispatch = useDispatch();
    const { t: translate } = useTranslation('signature');
    const [tab, setTab] = useState<TabType>('info');
    const { info, loading: infoLoading } = useSignatureInfo();
    const { bannerSections, loading: bannerLoading } = useBanners();

    const loading = infoLoading || bannerLoading;

    useEffect(() => {
        dispatch<any>(getSignatureInfo());
        dispatch<any>(getBanners());
    }, []);

    const {
        register,
        control,
        watch,
        handleSubmit,
        reset,
        setValue,
        formState: { errors, isValid, isSubmitting },
    } = useForm<ISignatureForm>({
        mode: 'all',
        resolver: yupResolver(schema(bannerSections, translate)),
    });

    useEffect(() => {
        if (!loading && info) {
            reset({
                firstName: info.FirstName || '',
                lastName: info.LastName || '',
                pronouns: info.Pronouns || '',
                emailAddress: info.EmailAddress || '',
                jobTitle: info.JobTitle || '',
                jobTitlePart2: info.JobTitlePart2 || '',
                department: info.Department || '',
                useDefaultDisclaimer: info.UseDefaultDisclaimer || false,
                disclaimer: info.Disclaimer || '',
                addressFormat: info.AddressFormat,
                street: info.Street || '',
                zipCode: info.ZipCode || '',
                city: info.City || '',
                country: info.Country || '',
                directPhone: info.DirectPhone || '',
                mobilePhone: info.MobilePhone || '',
                website: info.Website || '',
                freeText: info.FreeText || '',
                includeInOutlook: true,
                bannerId: info.BannerId,
                templateUrl: info.TemplateUrl || '',
                bannerLink: info.BannerLink || '',
                pictureUrl: info.PictureUrl || '',
                templateRessourceKey: info.TemplateRessourceKey || '',
            });
        }
    }, [loading, info]);

    const onSubmit: SubmitHandler<ISignatureForm> = async (data) => {
        const signatureInfo: SignatureInfoResponse = {
            FirstName: data.firstName,
            LastName: data.lastName,
            Pronouns: data.pronouns,
            JobTitle: data.jobTitle,
            JobTitlePart2: data.jobTitlePart2,
            Department: data.department,
            Street: data.street,
            City: data.city,
            Country: data.country,
            ZipCode: data.zipCode,
            DirectPhone: data.directPhone,
            MobilePhone: data.mobilePhone,
            EmailAddress: data.emailAddress,
            Website: data.website,
            AddressFormat: data.addressFormat,
            Disclaimer: data.disclaimer,
            UseDefaultDisclaimer: data.useDefaultDisclaimer,
            BannerId: data.bannerId,
            BannerLink: data.bannerLink,
            TemplateRessourceKey: data.templateRessourceKey,
            TemplateUrl: data.templateUrl,
            PictureUrl: data.pictureUrl,
            FreeText: data.freeText,
            LogoUrl: info?.LogoUrl,
        };
        await dispatch<any>(saveSignatureInfo(signatureInfo));

        if (data.includeInOutlook) {
            openSgLink();
        }

        onSave();
    };

    if (loading) {
        return <AccountSignatureSkeleton />;
    }

    const SaveButton = ({ wrapperClass }: { wrapperClass: string }) => {
        return <div className={wrapperClass}>{isSubmitting && <div className="me-2"><Loading /></div>}
            <button className="btn btn-flat-secondary" onClick={() => onCancel()}>
                {translate('signature:cancel')}
            </button>
            <button
                type="submit"
                className={classNames('btn btn-info ms-1', { disabled: !isValid || isSubmitting })}
                style={{ pointerEvents: isSubmitting ? 'none' : 'all' }}
                disabled={isSubmitting}
            >
                {translate('signature:save')}
            </button>
        </div>;
    };

    return <>
        <div className="w-100">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex pb-3">
                    <h3 className="h3">
                        {translate('title')}
                    </h3>
                    <SaveButton wrapperClass="d-flex ms-auto"></SaveButton>
                </div>
                <div className="signature-preview-border">
                    <PreviewSignature watch={watch} />
                </div>
                <TabsSignature activeTab={tab} onChange={setTab} />
                {tab === 'info' && <InfoPartSignature register={register} errors={errors} isSubmitting={isSubmitting} />}
                {tab === 'banner' && <BannerPartSignature control={control} setValue={setValue} isSubmitting={isSubmitting} />}
                <SaveButton wrapperClass="d-flex justify-content-end align-items-center"></SaveButton>
            </form>
        </div>
    </>;
};

const openSgLink = () => {
    const authHeader = getSGConnect().getAuthorizationHeader() || '';
    const token = authHeader.split(' ').pop();
    const url = `sglink://|outlook|signature-sgm|t|${token}`;
    window.open(url);
};
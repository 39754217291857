import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipInfoLink } from '../../components/details/InfoLink';
import { BookmarkStarButton } from '../../components/bookmark/BookmarkButtons';
import { ServiceLink } from 'components/common/service-link/ServiceLink';
import { ignoreRedirect } from 'core/services/redirectService';
import {
    DEFAULT_CATEGORY,
    DEFAULT_SGM_CATEGORY,
    NON_SGM_RELATED_TO_PLATFORM_SGM,
} from 'models/constants';
import { ServiceBoardServiceModel } from 'models/serviceBoardService';
import { FeatureTracked, ServiceTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import {
    ServiceTrackingInfoProvider,
    useServiceTrackingContext,
    useActionTrackingContext,
} from 'components/common/tracking/serviceTrackingContext';
import { RequestAccessIfMissing } from 'components/common/request-access/RequestAccessIfMissing';
import classNames from 'classnames';
import { Bookmark, BookmarkOrigin } from 'models/bookmark';
import { useBookmark } from 'components/hooks/bookmark/useBookmark';
import { AllMyServiceModel } from 'models/allMyServices';
import { useBoardServiceForDetail } from 'components/hooks/services/useBoardServices';

type AllServicesLineProps = {
    service: AllMyServiceModel;
    additionalBookmark?: Bookmark[];
    showBookmark: boolean;
    isSgmService: boolean;
};

const AllServicesLine: React.FunctionComponent<AllServicesLineProps> = ({
    service,
    additionalBookmark,
    isSgmService,
    showBookmark,
}) => {
    const { t: translate } = useTranslation('service');
    const { actionTrackedInfo } = useActionTrackingContext();

    const bookmarkOrigin = isSgmService ? BookmarkOrigin.SgmService : BookmarkOrigin.NonSgmService;

    const serviceTrackedUpdated: ServiceTracked = {
        topic: actionTrackedInfo.topic,
        service,
        origin: bookmarkOrigin,
    };

    const bookmarkReferenceId = isSgmService ? service.ServiceKey : service.Id;
    const platformRelatedTo = service.RelatedTo || '';
    const showAsSgmItem =
        isSgmService ||
        platformRelatedTo.toLowerCase() ===
        NON_SGM_RELATED_TO_PLATFORM_SGM.toLowerCase();
    return (
        <ServiceTrackingInfoProvider serviceTracked={serviceTrackedUpdated}>
            <li
                className={classNames(
                    'list-group-item p-0 me-1 me-sm-2 list-group-item-action',
                    {
                        'border-bottom border-opacity-40': !(
                            showBookmark &&
                            additionalBookmark &&
                            additionalBookmark.length > 0
                        ),
                    },
                )}
            >
                <div className="d-flex align-items-center justify-content-between text-primary">
                    <AllServicesLink
                        service={service}
                        className="d-inline-flex flex-grow-1 align-items-center p-1 p-sm-2 text-decoration-none"
                    >
                        <div
                            className={classNames(
                                'd-inline-flex d-flex justify-content-center align-items-center all-services-initials-icon border p-0',
                                {
                                    'text-black bg-white': showAsSgmItem,
                                    'text-secondary bg-opacity-10 bg-secondary':
                                        !showAsSgmItem,
                                },
                            )}
                        >
                            <span className="display-6 fw-medium overflow-hidden">
                                {service.Initials}
                            </span>
                        </div>
                        <div className="ms-3 d-inline-flex flex-column justify-content-center">
                            <span className="text-primary fw-semibold">
                                {service.Title}
                            </span>
                            <span className="text-secondary">
                                {service.Category?.Key
                                    ? translate(service.Category?.Key)
                                    : showAsSgmItem
                                        ? DEFAULT_SGM_CATEGORY
                                        : DEFAULT_CATEGORY}
                            </span>
                        </div>
                    </AllServicesLink>
                    <div
                        className="d-inline-flex float-right"
                        onClick={ignoreRedirect}
                        aria-hidden
                    >
                        <div>
                            {isSgmService && (
                                <RequestAccessIfMissing service={service} />
                            )}
                        </div>
                        <BookmarkStarButton
                            referenceId={bookmarkReferenceId}
                            origin={bookmarkOrigin}
                        />
                        <TooltipInfoLink
                            serviceKey={service.ServiceKey}
                            isSgmService={isSgmService}
                        />
                    </div>
                </div>
            </li>
            {showBookmark &&
                additionalBookmark &&
                <div className="border-bottom">
                    <ul className="list-group list-group-horizontal flex-wrap ms-5 ps-4">
                        {additionalBookmark.map((ab, i) => (
                            <li
                                key={i}
                                className="list-group-item p-0 me-2 pb-2">
                                <div
                                    className="list-group-item-inner-size"
                                >
                                    <a
                                        href={ab.PageUrl ?? undefined}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="text-primary bg-lvl2 d-flex flex-row align-items-center">
                                            <div>
                                                <span className="p-2 material-symbols-outlined">
                                                    note
                                                </span>
                                            </div>
                                            <div className="text-truncate flex-grow-1">
                                                <span className="">{ab.Title}</span>
                                            </div>
                                            <BookmarkStarButton
                                                referenceId={ab.ReferenceId}
                                                origin={BookmarkOrigin.SgDashboardBoard}
                                            />
                                        </div>
                                    </a>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </ServiceTrackingInfoProvider>
    );
};

type AllServicesLinkProps = {
    service: ServiceBoardServiceModel;
    className: string;
};
const AllServicesLink: React.FunctionComponent<
    PropsWithChildren<AllServicesLinkProps>
> = ({ service: { ServiceKey, Url }, className, children }) => {
    const { hasSgmServiceKey } = useBoardServiceForDetail();
    const isSgmService = hasSgmServiceKey(ServiceKey);
    const { trackService } = useServiceTracking();
    const { serviceTracked } = useServiceTrackingContext();

    return isSgmService ? (
        <ServiceLink serviceKey={ServiceKey} url={Url} className={className}>
            {children}
        </ServiceLink>
    ) : (
        <a
            href={Url || undefined}
            onClick={async () =>
                await trackService(FeatureTracked.OpenService, serviceTracked)
            }
            className={className}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </a>
    );
};

type ServicesCompactListProps = {
    services: AllMyServiceModel[];
    showBookmark?: (service: ServiceBoardServiceModel) => boolean;
    isSgmServices: boolean;
};

export const ServicesCompactList: React.FunctionComponent<
    ServicesCompactListProps
> = ({ services, showBookmark, isSgmServices }) => {
    const shouldShowBookmark = showBookmark || (() => true);
    const allbookmarks = useBookmark();
    return (
        <div>
            <ul className="list-group border-top w-100">
                {services.map((service, i) => {
                    const bookmarks =
                        service.ServiceKey?.toLocaleUpperCase() === 'SGM_DASHBOARD'
                            ? allbookmarks.bookmarks.filter(
                                (b) => b.Origin === 'SgDashboardBoard',
                            )
                            : undefined;
                    return (
                        <AllServicesLine
                            key={i}
                            service={service}
                            isSgmService={
                                service.IsSgmService
                                    ? service.IsSgmService
                                    : isSgmServices
                            }
                            additionalBookmark={bookmarks}
                            showBookmark={shouldShowBookmark(service)}
                        />
                    );
                })}
            </ul>
        </div>
    );
};

import React, { FC, KeyboardEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import classNames from 'classnames';
import { BookmarkStarButton } from 'components/pages/myServicesPage/components/bookmark/BookmarkButtons';
import { BookmarkOrigin } from 'models/bookmark';
import { RequestAccess } from '../request-access/RequestAccess';
import { TooltipInfoLink } from 'components/pages/myServicesPage/components/details/InfoLink';
import { useServiceDescriptionLink } from 'components/hooks/services/useServiceDescriptionLink';
import { ServiceBoardServiceModel } from 'models/serviceBoardService';
import { ServiceTrackingInfoProvider, useActionTrackingContext } from '../tracking/serviceTrackingContext';
import { FeatureTracked, ServiceTracked } from 'models/tracking';
import { redirectExternalOpenNewUrl } from 'core/services/redirectService';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useHasAccessServices } from 'components/hooks/services/useBoardServices';

import './ServiceCard.scss';

const buildLinearGradient = (url: Nullable<string>) =>
    `linear-gradient(180deg, rgba(167, 167, 167, 0.7) 0%, rgba(205, 205, 205, 0.15) 42.19%, rgba(205, 205, 205, 0.15) 83.33%, rgba(201, 201, 201, 0.8) 100%), url(${url})`;

interface IServiceCardProps {
    className?: string;
    service: ServiceBoardServiceModel;
}

export const ServiceCard: FC<IServiceCardProps> = ({ className, service }) => {
    const navigate = useNavigate();
    const { i18n: { language } } = useTranslation();
    const descriptionUrl = useServiceDescriptionLink(service?.ServiceKey);
    const { actionTrackedInfo } = useActionTrackingContext();
    const { trackService } = useServiceTracking();
    const { hasAccessTo } = useHasAccessServices();

    if (!service) {
        return null;
    }

    const description = language.toLowerCase() === 'fr' ? service.Descriptions?.fr : service.Descriptions?.en;
    const hasAccess = service.ServiceKey && hasAccessTo(service.ServiceKey);
    const canShowServiceDetail = (hasAccess && service.Url) || descriptionUrl;
    const cardAriaRole = canShowServiceDetail ? 'button' : undefined;
    const cardTabIndex = canShowServiceDetail ? 0 : -1;

    const serviceTrackedUpdated: ServiceTracked = {
        topic: actionTrackedInfo.topic,
        service: service,
        origin: BookmarkOrigin.SgmService,
    };

    const handleShowServiceDetail = () => {
        if (hasAccess && service.Url) {
            redirectExternalOpenNewUrl(service.Url);
            trackService(
                FeatureTracked.OpenService,
                serviceTrackedUpdated,
            );
        }
        else if (descriptionUrl) {
            navigate(descriptionUrl, { replace: true });
        }
    };

    const handleShowServiceDetailKeyUp: KeyboardEventHandler =
        (event) => event.key === 'Enter' && handleShowServiceDetail();

    return (
        <ServiceTrackingInfoProvider serviceTracked={serviceTrackedUpdated}>
            <div className={classNames('card card-raising bg-light bg-opacity-20 h-100', className)}>
                <div
                    className="h-100"
                    role={cardAriaRole}
                    tabIndex={cardTabIndex}
                    onClick={handleShowServiceDetail}
                    onKeyUp={handleShowServiceDetailKeyUp}
                >
                    <div
                        className="card-img-top p-3"
                        style={{
                            backgroundImage: buildLinearGradient(service.ImgUrl),
                        }}
                    >
                        <div className="card shadow d-flex justify-content-center align-items-center essentials-card-icon text-black bg-white p-0 display-5 ">
                            <span className="fw-medium">
                                {service.Initials}
                            </span>
                        </div>
                    </div>
                    <div className="card-body p-4">
                        <label className="card-subtitle text-truncate text-secondary d-block">{service.Category?.Name} &gt; {service.Subcategory?.Name}</label>
                        <h5 className="card-title text-truncate">{service.Title}</h5>
                        <p className="card-text text-secondary card-text-description">{description}</p>
                    </div>
                </div>
                <div className="card-footer text-muted p-0">
                    {hasAccess &&
                        <div className="d-flex justify-content-end">
                            <BookmarkStarButton
                                referenceId={service.ServiceKey}
                                origin={BookmarkOrigin.SgmService}
                            />
                            <TooltipInfoLink
                                serviceKey={service.ServiceKey}
                                isSgmService={true}
                            />
                        </div>}
                    {!hasAccess &&
                        <RequestAccess
                            serviceKey={service.ServiceKey || ''}
                            url={service.Url || ''}
                            buttonClass="btn-flat-primary w-100 border-top"
                        />}
                </div>
            </div>
        </ServiceTrackingInfoProvider>
    );
};

import { ServiceBoardServiceModel } from 'models/serviceBoardService';

export const RecommendationsFetchRequestActionType = '@RECOMMENDATIONS/FETCH_REQUEST';
export const RecommendationsFetchSuccessActionType = '@RECOMMENDATIONS/FETCH_SUCCESS';
export const RecommendationsFetchFailureActionType = '@RECOMMENDATIONS/FETCH_FAILURE';

export type RecommendationsFetchRequestAction = ReduxAction<
    typeof RecommendationsFetchRequestActionType
>;
export type RecommendationsFetchSuccessAction = ReduxAction<
    typeof RecommendationsFetchSuccessActionType,
    { payload: Nullable<ServiceBoardServiceModel[]> }
>;
export type RecommendationsFetchFailureAction = ReduxAction<
    typeof RecommendationsFetchFailureActionType
>;

export const createRecommendationsFetchRequestAction = (): RecommendationsFetchRequestAction => ({
    type: RecommendationsFetchRequestActionType,
});

export function createRecommendationsFetchSuccessAction(
    recommendedServices: Nullable<ServiceBoardServiceModel[]>,
): RecommendationsFetchSuccessAction {
    return {
        type: RecommendationsFetchSuccessActionType,
        payload: recommendedServices,
    };
}

export const createRecommendationsFetchFailureAction = (): RecommendationsFetchFailureAction => ({
    type: RecommendationsFetchFailureActionType,
});

export type RecommendationsAction =
    | RecommendationsFetchRequestAction
    | RecommendationsFetchSuccessAction
    | RecommendationsFetchFailureAction;

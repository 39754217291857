import { combineReducers } from 'redux';
import {
    HighlightLayoutsFetchSuccessActionType,
    HighlightLayoutsFetchRequestActionType,
    HighlightLayoutsFetchFailureActionType,
} from '../actions/highlightLayoutAction';
import { ActionType } from 'store/actions/ActionType';
import { MyHighlightLayoutModel } from 'models/highlightLayoutModels';

const layouts = (
    state: Nullable<MyHighlightLayoutModel[]> = null,
    action: ActionType,
) => {
    switch (action.type) {
        case HighlightLayoutsFetchSuccessActionType:
            {
                return action.payload;
            }
        default: {
            return state;
        }
    }
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case HighlightLayoutsFetchRequestActionType: {
            return true;
        }
        case HighlightLayoutsFetchFailureActionType:
        case HighlightLayoutsFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    layouts,
    isLoading,
});

import React, { FC } from 'react';
import { useHasAccessServices } from 'components/hooks/services/useBoardServices';
import { ServiceBoardServiceModel } from 'models/serviceBoardService';
import { RequestAccess } from './RequestAccess';

interface IRequestAccessIfMissingProps {
    service: ServiceBoardServiceModel;
    useIcon?: boolean;
    buttonClass?: string;
}

export const RequestAccessIfMissing: FC<IRequestAccessIfMissingProps> = ({ service, useIcon, buttonClass }) => {
    const { hasAccessTo } = useHasAccessServices();
    const { ServiceKey, Url } = service;

    if (!ServiceKey || hasAccessTo(ServiceKey)) {
        return null;
    }

    return (
        <RequestAccess
            serviceKey={ServiceKey}
            url={Url || ''}
            useIcon={useIcon}
            buttonClass={buttonClass}
        />
    );
};

import { AuthenticatedUser, UserContact } from 'models/authenticatedUser';

export const AuthenticatedUserFetchRequestActionType =
    '@AUTHENTICATED_USER/FETCH_REQUEST';
export const AuthenticatedUserFetchSuccessActionType =
    '@AUTHENTICATED_USER/FETCH_SUCCESS';
export const AuthenticatedUserFetchFailureActionType =
    '@AUTHENTICATED_USER/FETCH_FAILURE';

export const AuthenticatedUserContactsFetchRequestActionType =
    '@AUTHENTICATED_USER_CONTACTS/FETCH_REQUEST';
export const AuthenticatedUserContactsFetchSuccessActionType =
    '@AUTHENTICATED_USER_CONTACTS/FETCH_SUCCESS';
export const AuthenticatedUserContactsFetchFailureActionType =
    '@AUTHENTICATED_USER_CONTACTS/FETCH_FAILURE';

export type AuthenticatedUserFetchRequestAction = ReduxAction<
    typeof AuthenticatedUserFetchRequestActionType
>;
export type AuthenticatedUserFetchSuccessAction = ReduxAction<
    typeof AuthenticatedUserFetchSuccessActionType,
    { payload: Nullable<AuthenticatedUser> }
>;
export type AuthenticatedUserFetchFailureAction = ReduxAction<
    typeof AuthenticatedUserFetchFailureActionType
    >;

export type AuthenticatedUserContactsFetchRequestAction = ReduxAction<
    typeof AuthenticatedUserContactsFetchRequestActionType
>;
export type AuthenticatedUserContactsFetchSuccessAction = ReduxAction<
    typeof AuthenticatedUserContactsFetchSuccessActionType,
    { payload: Nullable<AuthenticatedUser[]> }
>;
export type AuthenticatedUserContactsFetchFailureAction = ReduxAction<
    typeof AuthenticatedUserContactsFetchFailureActionType
    >;

export const createAuthenticatedUserFetchRequestAction = (): AuthenticatedUserFetchRequestAction => ({
    type: AuthenticatedUserFetchRequestActionType,
});

export function createAuthenticatedUserFetchSuccessAction(
    user: Nullable<AuthenticatedUser>,
): AuthenticatedUserFetchSuccessAction {
    return {
        type: AuthenticatedUserFetchSuccessActionType,
        payload: user,
    };
}

export const createAuthenticatedUserFetchFailureAction = (): AuthenticatedUserFetchFailureAction => ({
    type: AuthenticatedUserFetchFailureActionType,
});

export const createAuthenticatedUserContactsFetchRequestAction = (): AuthenticatedUserContactsFetchRequestAction => ({
    type: AuthenticatedUserContactsFetchRequestActionType,
});

export function createAuthenticatedUserContactsFetchSuccessAction(
    user: Nullable<UserContact[]>,
): AuthenticatedUserContactsFetchSuccessAction {
    return {
        type: AuthenticatedUserContactsFetchSuccessActionType,
        payload: user,
    };
}

export const createAuthenticatedUserContactsFetchFailureAction = (): AuthenticatedUserContactsFetchFailureAction => ({
    type: AuthenticatedUserContactsFetchFailureActionType,
});

export type AuthenticatedUserAction =
    | AuthenticatedUserFetchRequestAction
    | AuthenticatedUserFetchSuccessAction
    | AuthenticatedUserFetchFailureAction;

export type AuthenticatedUserContactsAction =
    | AuthenticatedUserContactsFetchRequestAction
    | AuthenticatedUserContactsFetchSuccessAction
    | AuthenticatedUserContactsFetchFailureAction;
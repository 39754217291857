import { Dispatch } from 'react';
import { ActionType } from 'store/actions/ActionType';
import { ThunkResult } from 'store/store';
import {
    createFetchBannersAction,
    createFetchBannersSuccessAction,
    createFetchBannersFailureAction,
    createFetchBannerTemplateAction,
    createFetchBannerTemplateSuccessAction,
    createFetchBannerTemplateFailureAction,
} from 'store/actions/bannerAction';
import { needReload } from 'store/store-tools';

export const getBanners = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { serviceBoardClient, logger },
    ) => {
        const { banner: { list: request } } = state();

        if (!needReload(request)) {
            return;
        }

        dispatch(createFetchBannersAction());
        try {
            const banners = await serviceBoardClient.getBanners();
            dispatch(createFetchBannersSuccessAction(banners));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createFetchBannersFailureAction());
        }
    };
};

export const getBannerTemplate = (templateUrl: string): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { serviceBoardClient, logger },
    ) => {
        const { banner: { templates: request } } = state();

        if (!needReload(request[templateUrl])) {
            return;
        }

        dispatch(createFetchBannerTemplateAction(templateUrl));
        try {
            const templateContent = await serviceBoardClient.getBannerTemplate(templateUrl);
            dispatch(createFetchBannerTemplateSuccessAction(templateUrl, templateContent));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createFetchBannerTemplateFailureAction(templateUrl));
        }
    };
};


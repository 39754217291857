import { combineReducers, Middleware } from 'redux';
import { configureStore as configureStoreToolkit, Tuple } from '@reduxjs/toolkit';
import { ThunkAction, ThunkMiddleware, withExtraArgument } from 'redux-thunk';
import authentification from './reducers/authentificationReducer';
import disclaimer from './reducers/disclaimerReducer';
import boardServices from './reducers/serviceBoardServiceReducer';
import learnMore from './reducers/learnMoreReducer';
import recommendations from './reducers/recommendedServicesReducer';
import applicationContext from './reducers/applicationContextReducer';
import authenticatedUserContacts from './reducers/authenticatedUserContactReducer';
import introTour from './reducers/introTourReducer';
import { SgmSearchReducer } from './SgmSearch/sgmSearchReducer';
import bookmark from './reducers/bookmarkReducer';
import consent from './reducers/consentReducer';
import { CategoriesReducer } from './Categories/categoriesReducer';
import essentialService from './reducers/essentialServicesReducer';
import externalService from './reducers/externalServiceReducer';
import highlightLayout from './reducers/highlightLayoutReducer';
import myAccountSetting from './reducers/myAccountSettingReducer';
import myAccountPersonalInformation from './reducers/myAccountPersonalInformationReducer';
import signature from './reducers/signatureReducer';
import banner from './reducers/bannerReducer';
import { NormalizrReducer } from './Normalizr/NormalizrReducer';
import { IServiceBoardClient } from '../core/externalClient/IServiceBoardClient';
import { ActionType } from './actions/ActionType';
import { ITechnicalLogger } from 'core/services/common/logging/logger';
import { NotificationReducer } from './Notification/notificationReducer';
import { TFunction } from 'i18next';

export type Dependencies = {
    serviceBoardClient: IServiceBoardClient;
    logger: ITechnicalLogger;
    translate: TFunction;
};

const lastActionType = (_state: string, action: ActionType) => {
    return action.type;
};

const reducers = {
    authentification,
    disclaimer,
    lastActionType,
    applicationContext,
    boardServices,
    learnMore,
    recommendations,
    authenticatedUserContacts,
    search: SgmSearchReducer,
    bookmark,
    essentialService,
    introTour,
    consent,
    category: CategoriesReducer,
    externalService,
    highlightLayout,
    myAccountSetting,
    myAccountPersonalInformation,
    signature,
    banner,
    entities: NormalizrReducer,
    notification: NotificationReducer,
};

export type AppState = {
    [P in keyof typeof reducers]: ReturnType<typeof reducers[P]>;
};

export type ThunkResult<R = void> = ThunkAction<
    R,
    AppState,
    Dependencies,
    ActionType
>;

export type ApiRequest<T = undefined> = {
    readonly state: 'neverFetched' | 'isFetching' | 'valid' | 'outdated' | 'error';
    readonly data?: T;
};

export type Reducer<S = any, A = ActionType | { type: 'TEST' }> = (
    state: S | undefined,
    action: A
) => S

export const configureStore = (
    dependencies: Partial<Dependencies>,
    middlewares: Middleware[] = [],
) => configureStoreToolkit({
    reducer: combineReducers(reducers),
    middleware: () => new Tuple(
        withExtraArgument(dependencies) as ThunkMiddleware<
            AppState,
            ActionType,
            Partial<Dependencies>
        >,
        ...middlewares,
    ),
});

import { combineReducers } from 'redux';
import {
    ApplicationContextFetchSuccessActionType,
    ApplicationContextFetchRequestActionType,
    ApplicationContextFetchFailureActionType,
} from '../actions/applicationContextAction';
import {
    PreferredLanguageFetchRequestActionType,
    PreferredLanguageFetchSuccessActionType,
    PreferredLanguageFetchFailureActionType,
    ChangeAvailableLanguagesActionType,
} from '../actions/languageAction';
import { ActionType } from 'store/actions/ActionType';
import { ApplicationContext } from 'models/applicationContext';
import { ALL_LANGUAGES } from 'components/hooks/LanguageProvider';

const context = (
    state: Nullable<ApplicationContext> = null,
    action: ActionType,
) => {
    switch (action.type) {
        case ApplicationContextFetchSuccessActionType: {
            return action.payload;
        }
        case PreferredLanguageFetchSuccessActionType: {
            return {
                ...state,
                preferredLanguage: action.payload,
            };
        }
        default: {
            return state;
        }
    }
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case ApplicationContextFetchRequestActionType: {
            return true;
        }
        case ApplicationContextFetchFailureActionType:
        case ApplicationContextFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

const initialAvailableLanguagesState = ALL_LANGUAGES;
const availableLanguages = (state: string[] = initialAvailableLanguagesState, action: ActionType) => {
    switch (action.type) {
        case ChangeAvailableLanguagesActionType: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};

const changePreferredLanguageIsFetching = (
    state = false,
    action: ActionType,
) => {

    switch (action.type) {
        case PreferredLanguageFetchRequestActionType: {
            return true;
        }
        case PreferredLanguageFetchSuccessActionType:
        case PreferredLanguageFetchFailureActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};
export default combineReducers({
    context,
    isLoading,
    availableLanguages,
    changePreferredLanguageIsFetching,
});

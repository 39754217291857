import { Dispatch } from 'react';
import { ThunkResult } from 'store/store';
import {
    createServiceBoardServiceFetchFailureAction,
    createServiceBoardServiceFetchRequestAction,
    createServiceBoardServiceFetchSuccessAction,
} from 'store/actions/serviceBoardServiceAction';
import { ActionType } from 'store/actions/ActionType';
import { AccessType } from 'models/accessType';
import { DEFAULT_LANGUAGE } from 'models/constants';
import { matchServiceByKey } from 'core/utils/service-matchers';

export const buildServiceBoardServiceKey = (accessType: AccessType, language: string) => `${accessType}_${language}`;

export const getAccessServices = (language?: string): ThunkResult => {
    return getBoardServices(AccessType.HasAccess, language || DEFAULT_LANGUAGE);
};

export const getBoardServices = (accessType: AccessType, language?: string, additionalServices?: string): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { serviceBoardClient, logger },
    ) => {
        language ||= DEFAULT_LANGUAGE;
        const key = buildServiceBoardServiceKey(accessType, language);
        const { boardServices: { data } } = state();        
        if (Object.keys(data).includes(key)) {            
            return;
        }
        
        dispatch(createServiceBoardServiceFetchRequestAction(key));
        try {
            const service = await serviceBoardClient.getServices(accessType, language, additionalServices);
            dispatch(createServiceBoardServiceFetchSuccessAction(key, service?.Services, service?.NonSgmServices));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createServiceBoardServiceFetchFailureAction(key));
        }
    };
};

export const getServiceByKey = <T extends { ServiceKey?: Nullable<string> }>(
    haystack: Nullable<T[]>,
    key: string,
): T | null => {
    if (!haystack) {
        return null;
    }
    const matching = haystack.filter(matchServiceByKey(key));
    return matching[0] || null;
};

export const hasServiceKey = <T extends { ServiceKey?: Nullable<string> }>(
    haystack: Nullable<T[]>,
    key: Nullable<string>,
): boolean => {
    return !!haystack && !!key && haystack.some(matchServiceByKey(key));
};

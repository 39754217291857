import React, { PropsWithChildren } from 'react';
import { TooltipInfoLink } from '../../components/details/InfoLink';
import { BookmarkStarButton } from '../../components/bookmark/BookmarkButtons';
import { ServiceLink } from 'components/common/service-link/ServiceLink';
import { ignoreRedirect } from 'core/services/redirectService';
import { DEFAULT_CATEGORY } from 'models/constants';
import { FeatureTracked, ServiceTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import {
    ServiceTrackingInfoProvider,
    useServiceTrackingContext,
    useActionTrackingContext,
} from 'components/common/tracking/serviceTrackingContext';
import { RequestAccessIfMissing } from 'components/common/request-access/RequestAccessIfMissing';
import classNames from 'classnames';
import { BookmarkOrigin } from 'models/bookmark';
import { RelationType, SearchResultType, SearchResultItemSource, SgmSearchResultItemDto } from 'services/ApiService/SgmSearch/SgmSearchApiClient';
import { useBoardServiceForDetail } from 'components/hooks/services/useBoardServices';

export type ServiceSearchItemLineProps = {
    referenceId?: string;
    title?: string;
    url?: string;
    category?: string;
    initials?: string;
    isInternal?: boolean;
    description?: string;
    searchType: SearchResultType;
    searchItemSource?: SearchResultItemSource;
    relatedToRelationType?: RelationType;
    requestAccessInfo?: ServiceSearchRequestAccessInfo;
};

type ServiceSearchRequestAccessInfo = {
    serviceKey: string;
    url: string;
};

const transformToBookmarkOrigin = (searchItemSource?: SearchResultItemSource) => {
    if (!searchItemSource) return null;
    if (searchItemSource === SearchResultItemSource.SgmServiceConfig) {
        return BookmarkOrigin.SgmService;
    }
    if (searchItemSource === SearchResultItemSource.Dashboard) {
        return BookmarkOrigin.SgDashboardBoard;
    }

    return BookmarkOrigin.NonSgmService;
};

const ServiceSearchItemLine: React.FunctionComponent<
    ServiceSearchItemLineProps
> = ({
    referenceId,
    title,
    url,
    category,
    initials,
    isInternal,
    searchType,
    searchItemSource,
    description,
    requestAccessInfo,
}) => {
        const { actionTrackedInfo } = useActionTrackingContext();

        const isSgmService = searchItemSource === SearchResultItemSource.SgmServiceConfig;
        const bookmarkReferenceId = referenceId;
        const bookmarkOrigin = transformToBookmarkOrigin(searchItemSource);

        const serviceTrackedUpdated: ServiceTracked = {
            topic: actionTrackedInfo.topic,
            service: {
                Title: title,
                IsInternal: isInternal,
            },
            origin: bookmarkOrigin,
        };

        const serviceKey = searchItemSource === SearchResultItemSource.Dashboard ? 'sgm_dashboard' : referenceId;
        const isSgmItem = searchType === SearchResultType.SgmItem;
        const isRelatedToSgmPlatform = isSgmItem && searchItemSource &&
            [
                SearchResultItemSource.SgOtherConfig,
                SearchResultItemSource.Dashboard,
            ].indexOf(searchItemSource) >= 0;
        const relatedToSgmPlatformDescription = isRelatedToSgmPlatform
            ? description
            : null;
        const canRequestAccess =
            isSgmService && requestAccessInfo && !!requestAccessInfo.serviceKey;

        return (
            <ServiceTrackingInfoProvider serviceTracked={serviceTrackedUpdated}>
                <li className="list-group-item p-0 me-1 me-sm-2 list-group-item-action border-bottom border-opacity-40">
                    <div className="d-flex align-items-center justify-content-between text-primary">
                        <ServiceSearchItemLink
                            referenceId={referenceId}
                            url={url}
                            className="d-inline-flex flex-grow-1 align-items-center p-1 p-sm-2 text-decoration-none"
                        >
                            <div
                                className={classNames(
                                    'd-inline-flex d-flex justify-content-center align-items-center all-services-initials-icon border p-0',
                                    {
                                        'text-black bg-white': isSgmItem,
                                        'text-secondary bg-opacity-10 bg-secondary':
                                            !isSgmItem,
                                    },
                                )}
                            >
                                <span className="display-6 fw-medium overflow-hidden">
                                    {initials}
                                </span>
                            </div>
                            <div className="ms-3 d-inline-flex flex-column justify-content-center">
                                <span className="text-primary fw-semibold">
                                    {title}
                                </span>
                                <span className="text-secondary">
                                    {category || DEFAULT_CATEGORY}
                                </span>
                            </div>
                        </ServiceSearchItemLink>
                        <div
                            className="d-inline-flex float-right"
                            onClick={ignoreRedirect}
                            aria-hidden
                        >
                            <div>
                                {canRequestAccess && requestAccessInfo && (
                                    <RequestAccessIfMissing
                                        service={{
                                            ServiceKey:
                                                requestAccessInfo.serviceKey,
                                            Url: requestAccessInfo.url,
                                        }}
                                    />
                                )}
                            </div>
                            <BookmarkStarButton
                                referenceId={bookmarkReferenceId}
                                origin={bookmarkOrigin}
                            />
                            <TooltipInfoLink
                                serviceKey={serviceKey}
                                isSgmService={isRelatedToSgmPlatform || isSgmService}
                                tooltipText={relatedToSgmPlatformDescription}
                            />
                        </div>
                    </div>
                </li>
            </ServiceTrackingInfoProvider>
        );
    };

type ServiceSearchItemLinkProps = {
    referenceId?: string;
    url?: string;
    className: string;
};
const ServiceSearchItemLink: React.FunctionComponent<
    PropsWithChildren<ServiceSearchItemLinkProps>
> = ({ referenceId, url, className, children }) => {
    const { hasSgmServiceKey } = useBoardServiceForDetail();
    const isSgmService = hasSgmServiceKey(referenceId);
    const { trackService } = useServiceTracking();
    const { serviceTracked } = useServiceTrackingContext();

    return isSgmService ? (
        <ServiceLink serviceKey={referenceId} url={url} className={className}>
            {children}
        </ServiceLink>
    ) : (
        <a
            href={url}
            onClick={async () =>
                await trackService(FeatureTracked.OpenService, serviceTracked)
            }
            className={className}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </a>
    );
};

export type ServiceSearchItemCompactListProps = {
    serviceSearchItemLineInfos: ServiceSearchItemLineProps[];
};

export const ServiceSearchItemCompactList: React.FunctionComponent<
    ServiceSearchItemCompactListProps
> = ({ serviceSearchItemLineInfos }) => {
    return (
        <div>
            <ul className="list-group border-top w-100">
                {serviceSearchItemLineInfos.map(
                    (serviceSearchItemLineInfo, i) => (
                        <ServiceSearchItemLine
                            key={i}
                            referenceId={serviceSearchItemLineInfo.referenceId}
                            title={serviceSearchItemLineInfo.title}
                            url={serviceSearchItemLineInfo.url}
                            category={serviceSearchItemLineInfo.category}
                            initials={serviceSearchItemLineInfo.initials}
                            isInternal={serviceSearchItemLineInfo.isInternal}
                            searchItemSource={serviceSearchItemLineInfo.searchItemSource}
                            searchType={serviceSearchItemLineInfo.searchType}
                            description={serviceSearchItemLineInfo.description}
                            relatedToRelationType={serviceSearchItemLineInfo.relatedToRelationType}
                            requestAccessInfo={serviceSearchItemLineInfo.requestAccessInfo}
                        />
                    ),
                )}
            </ul>
        </div>
    );
};

export const getSgmItemRequestAccessInfo = (
    sgmSearchResultItem: SgmSearchResultItemDto,
): ServiceSearchRequestAccessInfo | undefined => {
    if (sgmSearchResultItem && sgmSearchResultItem.hasAccess === false) {
        const relatedTo = sgmSearchResultItem.sourceInfos?.at(0)?.relatedTo;
        if (relatedTo && relatedTo.relationType === RelationType.Page) {
            return {
                serviceKey: relatedTo.referenceId || '',
                url: relatedTo.url || '',
            };
        }
        return {
            serviceKey: sgmSearchResultItem.referenceId || '',
            url: sgmSearchResultItem.url || '',
        };
    }
};

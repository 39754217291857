import { CategoriesApi, SgmSearchApiSchema } from 'services/ApiService';
import { Dispatch } from 'react';
import { ActionType } from 'store/actions/ActionType';
import { createCategoryFetchFailureAction, createCategoryFetchRequestAction, createCategoryFetchSuccessAction } from './categoriesAction';
import { ThunkResult } from 'store/store';
import { normalize } from 'normalizr';
import { mergeSgmSearchEntities } from 'store/Normalizr/NormalizrAction';
import { needReload } from 'store/store-tools';

export const getCategories = (language?: string): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger },
    ) => {
        const { category: { categories } } = state();
        if (!needReload(categories)) {
            return;
        }

        dispatch(createCategoryFetchRequestAction());
        try {
            const searchResult = await CategoriesApi.getAvailableCategories(language || '', true);
            const normalizedData = normalize(searchResult.categories, SgmSearchApiSchema.CategorySchemaArray);
            dispatch(mergeSgmSearchEntities(normalizedData.entities));
            dispatch(createCategoryFetchSuccessAction(normalizedData.result));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createCategoryFetchFailureAction());
        }
    };
};

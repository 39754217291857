import React, { FC, useEffect } from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { MyServicesB2b2cPage } from '../myServicesPage/MyServicesB2b2cPage';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { CatalogPage } from '../catalogPage/CatalogPage';
import { CatalogBrowsePage } from '../catalogPage/catalogBrowse/CatalogBrowsePage';
import { CatalogForYouPage } from '../catalogPage/catalogForYou/CatalogForYouPage';
import NotFound from '../errors/NotFound';
import { MyServicesPage, EssentialsPage, EssentialsOrAllServicesLayout } from '../myServicesPage/MyServicesPage';
import { SearchPage } from '../search/SearchPage';
import { getBoardServices } from 'core/services/serviceBoardService/serviceBoardService';
import { AccessType } from 'models/accessType';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ServiceboardHeader } from 'components/layout/header/ServiceboardHeader';
import { Footer } from 'components/layout/footer/Footer';
import { CATALOG_PATH } from 'models/constants';
import { RedirectComponent } from './RedirectComponent';

export const ServiceBoardLayout: FC = () => {
    const dispatch = useDispatch();
    const { i18n: { language } } = useTranslation();
    const { hasOnlyB2b2cAccess } = useApplicationContext();

    useEffect(() => {
        dispatch<any>(getBoardServices(AccessType.All, language));
    }, [dispatch, language]);

    return <>
        <ServiceboardHeader minimalHeader={hasOnlyB2b2cAccess === true} />
        <Switch>
            <Route
                path="/"
                element={<RedirectComponent />}
            />
            <Route
                path="/services/*"
                element={<Navigate replace={true} to="/myservices" />}
            />
            {hasOnlyB2b2cAccess && <Route path="*" element={<MyServicesB2b2cPage />} />}
            {!hasOnlyB2b2cAccess && <>
                <Route path="/myservices" element={<MyServicesPage />}>
                    <Route index={true} element={<EssentialsPage />} />
                    <Route path="*" element={<EssentialsPage />} />
                    <Route
                        path="all-services/*"
                        element={<EssentialsOrAllServicesLayout />}
                    />
                </Route>

                <Route path={CATALOG_PATH} element={<CatalogPage />}>
                    <Route index={true} element={<CatalogForYouPage />} />
                    <Route path="*" element={<CatalogForYouPage />} />
                    <Route path="browse/*" element={<CatalogBrowsePage />} />
                </Route>

                <Route path="/search" element={<SearchPage />}>
                    <Route path=":searchTerm" />
                    <Route path=":searchTerm/:searchCategory" />
                    <Route path=":searchTerm/:searchCategory/*" />
                    <Route index={true} />
                    <Route path="*" />
                </Route>

                <Route
                    path="/search"
                    element={
                        <Navigate
                            replace={true}
                            to="/myservices/all-services"
                        />
                    }
                />

                <Route path="*" element={<NotFound />} />
            </>}
        </Switch>
        <Footer />
    </>;
};

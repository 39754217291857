import { AccessType } from 'models/accessType';
import { ApplicationContext } from 'models/applicationContext';
import { AuthenticatedUser, UserContact } from 'models/authenticatedUser';
import { Bookmark, BookmarkOrigin, Bookmarks } from 'models/bookmark';
import { DisclaimerContent } from 'models/disclaimerModels';
import { LearnMoreItem } from 'models/learnMoreItem';
import { ServiceBoardServiceModel, ServiceBoardServiceResponse } from 'models/serviceBoardService';
import { EssentialServicesModel } from 'models/essentialService';
import { getConfiguration } from '../services/common/configuration/configurationLoader';
import { fetcher } from '../services/common/http/fetcher';
import { IServiceBoardClient } from './IServiceBoardClient';
import { MyHighlightLayoutsModel } from 'models/highlightLayoutModels';
import { MyAccountPersonalInformation, MyAccountSetting } from 'models/myAccount';
import { SignatureInfoResponse } from 'models/signature';
import { BannerSectionsResponse } from 'models/banner';
import { Options } from 'intro.js/src/option';

export class ServiceBoardClient implements IServiceBoardClient {
    private serviceBoardUrl: string;

    constructor() {
        const { serviceBoardUrl } = getConfiguration();
        this.serviceBoardUrl = serviceBoardUrl;
    }

    public async markAsReadLearnMore(learnMoreId: number): Promise<void> {
        await fetcher<void>(
            `${this.serviceBoardUrl}/v2/learnmores/${learnMoreId}/do_markasread`,
            'POST',
        );
    }

    public async getServices(accessType: AccessType, language: string, additionalServices?: string): Promise<Nullable<ServiceBoardServiceResponse>> {
        const platform = 'DesktopBrowser';
        let url = `${this.serviceBoardUrl}/v2/services?platform=${platform}&accessType=${accessType}&language=${language}`;
        if (additionalServices) {
            url = `${url}&additionalServices=${additionalServices}`;
        }
        return await fetcher<ServiceBoardServiceResponse>(url, 'GET');
    }

    public async changePreferredLanguage(language: string): Promise<void> {
        await fetcher<void>(
            `${this.serviceBoardUrl}/v2/me/language/${language}`,
            'PUT',
        );
    }

    public async getApplicationContext(): Promise<
        Nullable<ApplicationContext>
    > {
        return await fetcher<ApplicationContext>(
            `${this.serviceBoardUrl}/v2/application/context`,
            'GET',
        );
    }

    public async getAvailableLearnMoreItem(): Promise<
        Nullable<LearnMoreItem[]>
    > {
        return await fetcher<LearnMoreItem[]>(
            `${this.serviceBoardUrl}/v2/learnmores?detailed=true`,
            'GET',
        );
    }

    public async getRecommendations(): Promise<
        Nullable<ServiceBoardServiceModel[]>
    > {
        return await fetcher<ServiceBoardServiceModel[]>(
            `${this.serviceBoardUrl}/v2/services/recommended`,
            'GET',
        );
    }

    public async getAuthenticatedUser(): Promise<Nullable<AuthenticatedUser>> {
        return await fetcher<AuthenticatedUser>(
            `${this.serviceBoardUrl}/v2/me`,
            'GET',
        );
    }

    public async generateDisclaimer(
        language?: string,
    ): Promise<Nullable<DisclaimerContent>> {
        let url = `${this.serviceBoardUrl}/v2/userdisclaimers/generate-content`;
        if (language) {
            url = `${url}?language=${language}`;
        }
        return await fetcher<DisclaimerContent>(url, 'GET');
    }

    public async getLastValidatedDisclaimer(): Promise<Nullable<DisclaimerContent>> {
        const url = `${this.serviceBoardUrl}/v2/userdisclaimers/last-validated-content`;
        return await fetcher<DisclaimerContent>(url, 'GET');
    }

    public async validateDisclaimer(application: string): Promise<void> {
        await fetcher<void>(
            `${this.serviceBoardUrl}/v2/userdisclaimers`,
            'POST',
            {
                application: application,
            },
        );
    }

    public async ignoreDisclaimer(): Promise<void> {
        await fetcher<void>(
            `${this.serviceBoardUrl}/v2/userdisclaimers/ignore`,
            'POST',
        );
    }

    public async getUserContacts(
        search = '',
    ): Promise<Nullable<UserContact[]>> {
        const searchParam = search ? `/${search}` : '';
        return await fetcher<UserContact[]>(
            `${this.serviceBoardUrl}/v2/me/contacts${searchParam}`,
            'GET',
        );
    }

    public async getUserBookmarks(): Promise<Nullable<Bookmarks>> {
        return await fetcher<Bookmarks>(
            `${this.serviceBoardUrl}/v2/bookmarks`,
            'GET',
        );
    }

    public async createUserBookmarks(
        referenceId: Nullable<string>,
        origin: Nullable<BookmarkOrigin>,
    ): Promise<Nullable<Bookmark>> {
        return await fetcher<Bookmark>(
            `${this.serviceBoardUrl}/v2/bookmarks`,
            'POST',
            {
                referenceId,
                origin,
            },
        );
    }

    public async deleteUserBookmarks(id: number): Promise<void> {
        await fetcher<void>(
            `${this.serviceBoardUrl}/v2/bookmarks/${id}`,
            'DELETE',
        );
    }

    public async getEssentialServices(
        language?: string,
    ): Promise<Nullable<EssentialServicesModel>> {
        let url = `${this.serviceBoardUrl}/v2/services/essentials`;
        if (language) {
            url = `${url}?language=${language}`;
        }
        return await fetcher<EssentialServicesModel>(url, 'GET');
    }

    public async getIntroTour(): Promise<Nullable<Options>> {
        const url = `${this.serviceBoardUrl}/v2/me/introduction-tour`;
        return await fetcher<Options>(url, 'GET');
    }

    public async getMyHighlightLayouts(language: string): Promise<Nullable<MyHighlightLayoutsModel>> {
        let url = `${this.serviceBoardUrl}/v2/highlight-layouts/me`;
        if (language) {
            url = `${url}?language=${language}`;
        }
        return await fetcher<MyHighlightLayoutsModel>(url, 'GET');
    }

    public async getMyAccountSetting(): Promise<
        Nullable<MyAccountSetting>
    > {
        return await fetcher<MyAccountSetting>(
            `${this.serviceBoardUrl}/v2/my-accounts/settings`,
            'GET',
        );
    }

    public async getMyAccountPersonalInformation(): Promise<
        Nullable<MyAccountPersonalInformation>
    > {
        return await fetcher<MyAccountPersonalInformation>(
            `${this.serviceBoardUrl}/v2/my-accounts/personal-information`,
            'GET',
        );
    }

    public async getSignatureBody(): Promise<Nullable<string>> {
        const url = `${this.serviceBoardUrl}/v2/signature/body/me`;
        return await fetcher<string>(url, 'GET', undefined, true);
    }

    public async getSignatureInfo(): Promise<Nullable<SignatureInfoResponse>> {
        const url = `${this.serviceBoardUrl}/v2/signature`;
        return await fetcher<SignatureInfoResponse>(url, 'GET');
    }

    public async saveSignatureInfo(signature: SignatureInfoResponse): Promise<void> {
        const url = `${this.serviceBoardUrl}/v2/signature`;
        await fetcher<void>(url, 'POST', signature);
    }

    public async getBanners(): Promise<Nullable<BannerSectionsResponse>> {
        const url = `${this.serviceBoardUrl}/v2/banner/all`;
        return await fetcher<BannerSectionsResponse>(url, 'GET');
    }

    public async getBannerTemplate(templateUrl: string): Promise<Nullable<string>> {
        return await fetcher<string>(templateUrl, 'GET', undefined, true);
    }
}

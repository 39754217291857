import { MyHighlightLayoutModel } from 'models/highlightLayoutModels';
import React, { FC } from 'react';
import { HighlightCard } from './HighlightCard';
import { Scroller } from 'components/common/scroll/Scroller';

type HighlightLayoutProps = {
    layout: Nullable<MyHighlightLayoutModel>
};

export const HighlightLayout: FC<HighlightLayoutProps> = ({ layout }) => {
    if (!layout) {
        return <></>;
    }

    const noScroll = !!layout.items?.filter(x => x.highlightType === 'Recommended')?.length;

    return <>
        <div className="d-flex">
            <div className="flex-grow-1">
                <h3 className="mb-0">
                    {layout.title}
                </h3>
                <div className="fs-3 mb-3 text-secondary">
                    {layout.subTitle}
                </div>
            </div>
            {layout.viewMoreTitle && layout.viewMoreUrl && <div>
                <a
                    href={layout.viewMoreUrl}
                    target={layout.viewMoreTargetBlank ? '_blank' : '_self'}
                    rel="noreferrer"
                    className="btn btn-lg btn-icon-end btn-flat-secondary mb-md-0" >
                    {layout.viewMoreTitle} <i className="ms-1 icon icon-lg">arrow_forward_ios</i>
                </a>
            </div>}
        </div>
        <Scroller className="row g-2 g-md-3 flex-nowrap" disabled={noScroll}>
            {layout.items && layout.items.map((highlight, index) =>
                <HighlightCard highlight={highlight} key={index} />,
            )}
        </Scroller>
    </>;
};

import classNames from 'classnames';
import { DefaultTooltip } from 'components/common/tooltip/Tooltip';
import { useMediaBreakpoint } from 'components/hooks/media/useMediaBreakpoint';
import { useExternalService } from 'components/hooks/services/useExternalService';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { scrollUp } from 'core/utils/animations';
import { CATALOG_PATH } from 'models/constants';
import { NavigationCatalogNavbar, NavigationCatalogNavbarValues } from 'models/navigation';
import { FeatureTracked, TopicTracked } from 'models/tracking';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { createExternalServiceSetOnlySuccessAction } from 'store/actions/externalServiceAction';

export const CatalogNavBar: FC = () => {
    const { t: translate } = useTranslation('catalog');
    const mediaBreakpoint = useMediaBreakpoint();
    const { showOnlyExternalService } = useExternalService();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { isSgGroupCurrentUser } = useApplicationContext();
    const { trackAction } = useServiceTracking();

    const catalogTabPathName = pathname.replace(`${CATALOG_PATH}/`, '').split('/')[0] as NavigationCatalogNavbar;
    const active: NavigationCatalogNavbar = NavigationCatalogNavbarValues.includes(catalogTabPathName) ? catalogTabPathName : 'for-you';

    const showViewAsClientTooltip = ['xs', 'sm'].includes(mediaBreakpoint);

    const toggleExternalServiceOnly = () => {
        dispatch<any>(createExternalServiceSetOnlySuccessAction(!showOnlyExternalService));
        trackAction(FeatureTracked.ViewAsClient, { topic: TopicTracked.CatalogBrowse });
    };

    const isBrowseActivated = active === 'browse';
    const viewAsClientTranslated = translate(showOnlyExternalService ? 'catalog:viewingAsClient' : 'catalog:viewAsClient');

    return (
        <div className="d-flex align-items-center py-md-3 py-1">
            <div id="navs" className="d-block h2">
                <Nav>
                    <NavItem>
                        <CatalogNavBarLink
                            className={classNames('nav-link fw-bold ps-0 me-4', {
                                active: active === 'for-you',
                            })}
                            to="for-you"
                        >
                            {translate('catalog:forYou')}
                        </CatalogNavBarLink>
                    </NavItem>
                    <NavItem>
                        <CatalogNavBarLink
                            className={classNames('nav-link fw-bold ps-0', {
                                active: isBrowseActivated,
                            })}
                            to="browse"
                        >
                            {translate('catalog:browse')}
                        </CatalogNavBarLink>
                    </NavItem>
                </Nav>
            </div>
            {isBrowseActivated && isSgGroupCurrentUser && <>
                <div className="flex-grow-1" />
                <div className="btn-group btn-group-toggle">
                    <DefaultTooltip                        
                        text={viewAsClientTranslated}
                        enabled={showViewAsClientTooltip}
                    >
                        <button
                            id="catalog-view-as-client"
                            className={classNames('btn btn-toggle-info', { 'active': showOnlyExternalService })}
                            onClick={toggleExternalServiceOnly}>
                            <span className="d-none d-md-block">{viewAsClientTranslated}</span>
                            <i className="icon d-block d-md-none">contacts</i>
                        </button>
                    </DefaultTooltip>
                </div>
            </>}
        </div>
    );
};

type CatalogNavBarLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
    to: NavigationCatalogNavbar;
};
export const CatalogNavBarLink: React.FunctionComponent<CatalogNavBarLinkProps> = ({
    to,
    children,
    ...linkProps
}) => {
    const { trackAction } = useServiceTracking();

    const onLinkClicked = async () => {
        scrollUp();
        if (to === 'browse') {
            await trackAction(FeatureTracked.OpenCatalogBrowse, { topic: TopicTracked.CatalogBrowse });
        } else if (to === 'for-you') {
            await trackAction(FeatureTracked.OpenCatalogForYou, { topic: TopicTracked.CatalogForYou });
        }
    };
    return (
        <Link to={`/catalog/${to}`} onClick={onLinkClicked} {...linkProps}>
            {children}
        </Link>
    );
};
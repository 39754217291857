import { Dispatch } from 'react';
import { ThunkResult } from 'store/store';
import {
    createBookmarkFetchFailureAction,
    createBookmarkFetchRequestAction,
    createBookmarkFetchSuccessAction,

    createBookmarkCreateFailureAction,
    createBookmarkCreateRequestAction,
    createBookmarkCreateSuccessAction,

    createBookmarkDeleteFailureAction,
    createBookmarkDeleteRequestAction,
    createBookmarkDeleteSuccessAction,
} from 'store/actions/bookmarkAction';
import { BookmarkOrigin } from 'models/bookmark';
import { toastSuccess } from 'components/common/toaster/toast';

export const getUserBookmarks = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { serviceBoardClient, logger },
    ) => {
        dispatch(createBookmarkFetchRequestAction());
        try {
            const bookmarks = await serviceBoardClient.getUserBookmarks();
            dispatch(createBookmarkFetchSuccessAction(bookmarks?.Items ?? null));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createBookmarkFetchFailureAction());
        }
    };
};

export const createUserBookmark = (referenceId: Nullable<string>, origin: Nullable<BookmarkOrigin>): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { serviceBoardClient, logger, translate },
    ) => {
        dispatch(createBookmarkCreateRequestAction());
        try {
            const bookmarkCreated = await serviceBoardClient.createUserBookmarks(referenceId, origin);
            toastSuccess(translate('bookmark:addToBookmarkToaster', { title: bookmarkCreated?.Title ?? '' }));
            dispatch(createBookmarkCreateSuccessAction(bookmarkCreated));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createBookmarkCreateFailureAction());
        }
    };
};

export const deleteUserBookmark = (id: number): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        state,
        { serviceBoardClient, logger, translate },
    ) => {
        dispatch(createBookmarkDeleteRequestAction());
        try {
            await serviceBoardClient.deleteUserBookmarks(id);
            const title = state().bookmark?.items?.filter(e => e.Id === id)[0]?.Title ?? '';
            toastSuccess(translate('bookmark:removeBookmarkToaster', { title }));
            dispatch(createBookmarkDeleteSuccessAction(id));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createBookmarkDeleteFailureAction());
        }
    };
};
import React, { FC } from 'react';
import { ServiceCard } from 'components/common/service-card/ServiceCard';
import { useCatalogServices } from 'components/hooks/services/useBoardServices';
import { MyHighlightServiceModel } from 'models/highlightLayoutModels';
import { getHighlightItemColCss } from './HighlightCard';
import classnames from 'classnames';

type HighlightServiceCardProps = {
    highlightService: Nullable<MyHighlightServiceModel>
    columnSizing: Nullable<number>
}

export const HighlightServiceCard: FC<HighlightServiceCardProps> = ({ highlightService, columnSizing }) => {
    const { getSgmServiceByKey } = useCatalogServices();

    if (!highlightService || !highlightService.serviceKey) {
        return <></>;
    }

    const service = getSgmServiceByKey(highlightService.serviceKey ?? '');
    if (!service) {
        return <></>;
    }

    return (
        <div className={classnames('highlight-card', getHighlightItemColCss(columnSizing))}>
            <ServiceCard service={service} />
        </div>
    );
};
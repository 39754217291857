import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createLearnMoreItemFetchFailureAction,
    createLearnMoreItemFetchRequestAction,
    createLearnMoreItemFetchSuccessAction,
} from '../../../store/actions/learnMoreItemAction';

export const getAvailableLearnMore = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { serviceBoardClient, logger },
    ) => {
        dispatch(createLearnMoreItemFetchRequestAction());
        try {
            const learnMore = await serviceBoardClient.getAvailableLearnMoreItem();
            dispatch(createLearnMoreItemFetchSuccessAction(learnMore));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createLearnMoreItemFetchFailureAction());
        }
    };
};

export const markAsReadLearnMore = (learnMoreId: number): ThunkResult => {
    return async (
        _dispatch: Dispatch<any>,
        _state,
        { serviceBoardClient, logger },
    ) => {
        try {
            await serviceBoardClient.markAsReadLearnMore(learnMoreId);
        } catch (error: any) {
            logger.error(error.message, error);
        }
    };
};

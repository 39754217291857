import { MyHighlightLayoutModel } from 'models/highlightLayoutModels';

export const HighlightLayoutsFetchRequestActionType = '@HIGHTLIGHTS_LAYOUT/FETCH_REQUEST';
export const HighlightLayoutsFetchSuccessActionType = '@HIGHTLIGHTS_LAYOUT/FETCH_SUCCESS';
export const HighlightLayoutsFetchFailureActionType = '@HIGHTLIGHTS_LAYOUT/FETCH_FAILURE';

export type HighlightLayoutsFetchRequestAction = ReduxAction<
    typeof HighlightLayoutsFetchRequestActionType
>;
export type HighlightLayoutsFetchSuccessAction = ReduxAction<
    typeof HighlightLayoutsFetchSuccessActionType,
    { payload: Nullable<MyHighlightLayoutModel[]> }
>;
export type HighlightLayoutsFetchFailureAction = ReduxAction<
    typeof HighlightLayoutsFetchFailureActionType
>;

export const createHighlightLayoutsFetchRequestAction = (): HighlightLayoutsFetchRequestAction => ({
    type: HighlightLayoutsFetchRequestActionType,
});

export function createHighlightLayoutsFetchSuccessAction(
    highlightLayouts: Nullable<MyHighlightLayoutModel[]>,
): HighlightLayoutsFetchSuccessAction {
    return {
        type: HighlightLayoutsFetchSuccessActionType,
        payload: highlightLayouts,
    };
}

export const createHighlightLayoutsFetchFailureAction = (): HighlightLayoutsFetchFailureAction => ({
    type: HighlightLayoutsFetchFailureActionType,
});

export type HighlightLayoutAction =
    | HighlightLayoutsFetchRequestAction
    | HighlightLayoutsFetchSuccessAction
    | HighlightLayoutsFetchFailureAction;

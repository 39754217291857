import { combineReducers } from 'redux';
import {
    MyAccountSettingFetchSuccessActionType,
    MyAccountSettingFetchRequestActionType,
    MyAccountSettingFetchFailureActionType,
} from '../actions/myAccountSettingAction';
import { ActionType } from 'store/actions/ActionType';
import { MyAccountSetting } from 'models/myAccount';

const setting = (
    state: Nullable<MyAccountSetting> = null,
    action: ActionType,
) => {
    switch (action.type) {
        case MyAccountSettingFetchSuccessActionType: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};

const isLoading = (state = false, action: ActionType) => {
    switch (action.type) {
        case MyAccountSettingFetchRequestActionType: {
            return true;
        }
        case MyAccountSettingFetchFailureActionType:
        case MyAccountSettingFetchSuccessActionType: {
            return false;
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    setting,
    isLoading,
});

import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { groupBy, sortBy } from 'lodash-es';
import { extractCategoryKey } from 'components/hooks/services/useCategories';
import { CATALOG_BROWSE_PATH } from 'models/constants';

import { ServiceBoardServiceModel } from 'models/serviceBoardService';
import { ActionTrackedInfo, FeatureTracked } from 'models/tracking';
import { useServiceTracking } from 'components/hooks/tracking/useServiceTracking';
import { handleA11y } from 'core/utils/a11y';

import './CatalogBrowseSubCategoryFilter.scss';

export interface ICatalogBrowseSubCategoryFilterProps {
    categoryServices: ServiceBoardServiceModel[];
    activeCategory: {
        Key?: string | null;
        Name?: string | null;
    };
    activeSubCategory: Nullable<{
        Key?: string | null;
        Name?: string | null;
    }>;
    trackInfos: (category: {
        Key?: string | null;
        Name?: string | null;
    }, subcategory: {
        Key?: string | null;
        Name?: string | null;
    }) => ActionTrackedInfo

}

export const allSubCategoryKey = 'all';

interface IOption {
    key: string;
    label: string;
    count: number;
}

export const CatalogBrowseSubCategoryFilter: FC<ICatalogBrowseSubCategoryFilterProps> = ({ categoryServices, activeCategory, activeSubCategory, trackInfos }) => {
    const { t: translate } = useTranslation('catalog');
    const navigate = useNavigate();
    const { trackAction } = useServiceTracking();

    const servicesBySubCategory = groupBy(sortBy(categoryServices, c => c.Subcategory?.Name ?? c), x => x.Subcategory?.Key);
    const activeSubCategoryKey = activeSubCategory?.Key || allSubCategoryKey;

    const options: IOption[] = useMemo(() => [{ key: allSubCategoryKey, label: 'All', count: categoryServices.length }, ...Object.keys(servicesBySubCategory)
        .map(x => {
            const subCategoryServices = servicesBySubCategory[x];
            const serviceCount = subCategoryServices.length;
            const subCat = subCategoryServices[0].Subcategory;
            return {
                key: subCat?.Key || '',
                label: subCat?.Name || '',
                count: serviceCount,
            };
        })], [servicesBySubCategory]);

    const getOptionSelected = (): string => {
        return options.find(o => o.key === activeSubCategoryKey)?.label || 'All';
    };

    const buildSubCategoryUrl = (subCategoryKey: string): string => {
        return `${CATALOG_BROWSE_PATH}/${extractCategoryKey(activeCategory.Key)}/${extractCategoryKey(subCategoryKey)}`;
    };

    const handleSubCategoryFilter = (option: IOption) => {
        const subCategoryUrl = `${CATALOG_BROWSE_PATH}/${extractCategoryKey(activeCategory.Key)}/${extractCategoryKey(option.key)}`;
        navigate(subCategoryUrl);
        return trackAction(FeatureTracked.FilterBySubCategory, trackInfos(activeCategory, {
            Key: option.key,
            Name: option.label,
        }));
    };

    return <>
        <div className="d-flex align-items-center mt-4 mb-2">
            <h3>{translate('catalog:browseByCategories')}</h3>
        </div>
        <div className="form-group d-flex flex-wrap gap-1 catalog-browse-category">
            <div className="btn-group border flex-grow-1 d-sm-inline-flex d-sm-none">
                <button className="btn btn-flat-primary btn-lg dropdown-toggle m-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {translate('catalog:subCategoryFilterBy', {
                        'optionName': getOptionSelected(),
                    })}
                </button>
                <ul className="dropdown-menu">
                    {options.map((op) => {
                        return <li
                            key={op.key}
                            id={`subcategory_${op.key}`}
                        >
                            <div
                                className={classNames('dropdown-item d-flex', { 'active': activeSubCategoryKey === op.key })}
                                onClick={handleA11y(() => handleSubCategoryFilter(op))}
                                onKeyUp={handleA11y(() => handleSubCategoryFilter(op))}
                                role="option"
                                aria-selected={activeSubCategoryKey === op.key}
                                tabIndex={0}
                            >
                                <span className="me-auto">{op.label}</span>
                                <span className="ms-2 text-end">{op.count}</span>
                            </div>
                        </li>;
                    })}
                </ul>
            </div>
            {options.map((op) => {
                const subCategoryUrl = buildSubCategoryUrl(op.key);
                return <Link
                    key={op.key}
                    to={subCategoryUrl}
                    className={classNames('form-check form-check-category d-none d-sm-block', { 'checked': activeSubCategoryKey === op.key })}
                    onClick={handleA11y(() => handleSubCategoryFilter(op))}
                    onKeyUp={handleA11y(() => handleSubCategoryFilter(op))}
                >
                    <input
                        checked={activeSubCategoryKey === op.key}
                        className="form-check-input"
                        type="radio"
                        name="subcategory"
                        id={`subcategory_${op.key}`}
                        readOnly
                        tabIndex={-1}
                    />
                    <label className="form-check-label text-nowrap" htmlFor={`subcategory_${op.key}`}>
                        {op.label}<span className="mx-2 text-end">{op.count}</span>
                    </label>
                </Link>;
            })}
        </div>
    </>;
};
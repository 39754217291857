import introJs from 'intro.js';
import { Options } from 'intro.js/src/option';
import { IntroJs } from 'intro.js/src/intro';
import { Dispatch } from 'react';
import { ActionType } from 'store/actions/ActionType';
import { createIntroTourFetchFailureAction, createIntroTourFetchRequestAction, createIntroTourFetchSuccessAction } from 'store/actions/introTourAction';
import { ThunkResult } from 'store/store';
import './introJs.scss';

// const INTRODUCTION_TOUR_DONE = 'serviceboard-introduction-tour-done';
const INTROJS_ATTRIBUTE = 'data-intro-js';

declare global {
    interface Window {
        introJs: () => IntroJs;
    }
}

export const GetIntroTour = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        _state,
        { serviceBoardClient, logger },
    ) => {
        dispatch(createIntroTourFetchRequestAction());
        try {
            const introTour = await serviceBoardClient.getIntroTour();
            dispatch(createIntroTourFetchSuccessAction(introTour));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createIntroTourFetchFailureAction());
        }
    };
};

export const setupIntroductionTour = (introTourData: Options) => {
    setIntroductionTour(introTourData);
    displayIntroductionTourIfIsFirstVisit();
};

const displayIntroductionTourIfIsFirstVisit = () => {
    // SGMI-11011 Temporary disable introduction tour
    // const isFirstVisit = localStorage.getItem(INTRODUCTION_TOUR_DONE) === null;

    // if (isFirstVisit) {
    //     window.introJs().start();
    //     localStorage.setItem(INTRODUCTION_TOUR_DONE, new Date().toISOString());
    // }
};

export const displayIntroductionTour = (introTourData: Options) => {
    setIntroductionTour(introTourData);
    window.introJs().start();
};

const setIntroductionTour = (data: Options) => {
    const customIntroJs = introJs();
    customIntroJs.setOptions(data);
    enableIntroductionTourButtonAction();
    window.introJs = () => customIntroJs;
};

const enableIntroductionTourButtonAction = () => {
    window.onclick = (event: MouseEvent) => {
        const target = event.target as HTMLElement;
        const targetParent = target.parentElement as HTMLElement;

        const attribute =
            target?.getAttribute(INTROJS_ATTRIBUTE) ||
            targetParent?.getAttribute(INTROJS_ATTRIBUTE);
        if (attribute) {
            event.stopPropagation();
            executeIntroductionTourAction(attribute);
        }
    };
};

const executeIntroductionTourAction = (actionName: string) => {
    const actions: Record<string, () => void> = {
        'exit': () => window.introJs().exit(false),
        'previous': () => window.introJs().previousStep(),
        'next': () => window.introJs().nextStep(),
        'refresh': () => window.introJs().refresh(),
        'restart': () => window.introJs().goToStepNumber(1),
    };

    actions[actionName] && actions[actionName]();
};

import { SignatureInfoResponse } from 'models/signature';
import { useSelector, shallowEqual } from 'react-redux';
import { AppState, ApiRequest } from 'store/store';

export const useSignatureBody = (): {
    loading: boolean,
    body: Nullable<string>,
} => {
    const { state, data } = useSelector<AppState, ApiRequest<string>>(state => state.signature.body, shallowEqual);

    return {
        loading: state === 'neverFetched' || state === 'isFetching',
        body: data,
    };
};

export const useSignatureInfo = (): {
    loading: boolean,
    info: Nullable<SignatureInfoResponse>,
} => {
    const { state, data } = useSelector<AppState, ApiRequest<SignatureInfoResponse>>(state => state.signature.info, shallowEqual);

    return {
        loading: state === 'neverFetched' || state === 'isFetching',
        info: data,
    };
};

import React, { FC } from 'react';
import classnames from 'classnames';
import { useCatalogServices } from 'components/hooks/services/useBoardServices';
import { MyHighlightInsightPublicModel } from 'models/highlightLayoutModels';
import { ServiceCard } from 'components/common/service-card/ServiceCard';
import { getHighlightItemColCss } from './HighlightCard';
import { InsightPublicCard } from './InsightPublicCard';

type HighlightInsightPublicCardProps = {
    insightPublic: Nullable<MyHighlightInsightPublicModel>
}

export const HighlightInsightPublicCard: FC<HighlightInsightPublicCardProps> = ({ insightPublic }) => {
    const { getSgmServiceByKey } = useCatalogServices();
    const service = getSgmServiceByKey(insightPublic?.serviceKey ?? '');

    return <>
        {service && <div className={classnames('highlight-card', getHighlightItemColCss(2))}>
            <ServiceCard service={service} />
        </div>}
        {insightPublic?.insights && insightPublic.insights.length > 0 &&
            <div className={classnames(getHighlightItemColCss(2))}>
                <div className="row g-3">
                    {insightPublic.insights.map((x, index) => <InsightPublicCard insight={x} key={index} />)}
                </div>
            </div>}
    </>;
};

import { Dispatch } from 'react';
import { ThunkResult } from '../../../store/store';
import {
    createRecommendationsFetchFailureAction,
    createRecommendationsFetchRequestAction,
    createRecommendationsFetchSuccessAction,
} from '../../../store/actions/recommendationsAction';

export const getRecommendations = (): ThunkResult => {
    return async (
        dispatch: Dispatch<any>,
        _state,
        { serviceBoardClient, logger },
    ) => {
        dispatch(createRecommendationsFetchRequestAction());
        try {
            const recommendedServices = await serviceBoardClient.getRecommendations();
            dispatch(createRecommendationsFetchSuccessAction(recommendedServices));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createRecommendationsFetchFailureAction());
        }
    };
};

import { toastError } from 'components/common/toaster/toast';
import { Dispatch } from 'react';
import { NotificationsApi, NotificationTrackingsApi } from 'services/ApiService/Notification/NotificationApi';
import { ReadStatus } from 'services/ApiService/Notification/NotificationApiClient';
import { ActionType } from 'store/actions/ActionType';
import {
    createTrackUserNotificationClickFetchRequestAction,
    createTrackUserNotificationClickFetchSuccessAction,
    createTrackUserNotificationClickFetchFailureAction,
    createNotificationCountByProducersFetchRequestAction,
    createNotificationCountByProducersFetchSuccessAction,
    createNotificationCountByProducersFetchFailureAction,
    createSearchNotificationsFetchRequestAction,
    createSearchNotificationsFetchSuccessAction,
    createSearchNotificationsFetchFailureAction,
    createTrackUserNotificationReadFetchRequestAction,
    createTrackUserNotificationReadFetchSuccessAction,
    createTrackUserNotificationReadFetchFailureAction,
    createInternalMarkAsReadAction,
} from 'store/Notification/notificationAction';
import { ThunkResult } from 'store/store';
import { needReload } from 'store/store-tools';

export const PAGE_SIZE = 20;

export const doMarkAsClicked = (notificationId: number): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger },
    ) => {
        const { notification: { markAsClicked } } = state();
        if (markAsClicked.state === 'isFetching') {
            return;
        }

        dispatch(createTrackUserNotificationClickFetchRequestAction());
        try {
            const markAsClickedResponse = await NotificationTrackingsApi.putMarkAsClicked(notificationId);
            dispatch(createTrackUserNotificationClickFetchSuccessAction(markAsClickedResponse));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createTrackUserNotificationClickFetchFailureAction());
        }
    };
};

export const getCountByProducers = (): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger },
    ) => {
        const { notification: { countByProducers } } = state();
        if (!needReload(countByProducers)) {
            return;
        }

        dispatch(createNotificationCountByProducersFetchRequestAction());
        try {
            const countByProducersResponse = await NotificationsApi.getCountByProducers(false);
            dispatch(createNotificationCountByProducersFetchSuccessAction(countByProducersResponse?.items));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createNotificationCountByProducersFetchFailureAction());
        }
    };
};

export const getSearchKey = (producerCode: string | undefined, readStatus: ReadStatus): string => {
    return `${producerCode}_${readStatus}`;
};

export const searchNotifications = (producerCode: string | undefined, readStatus: ReadStatus, fetchNextPage: boolean): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger },
    ) => {
        const { notification: { searchResult } } = state();
        const searchKey = getSearchKey(producerCode, readStatus);

        if (fetchNextPage && (searchResult.searchKey !== searchKey || searchResult.state === 'isFetching' || searchResult.isLastPage)) {
            return;
        }
        if (!fetchNextPage && (searchResult.searchKey === searchKey && !needReload(searchResult))) {
            return;
        }

        dispatch(createSearchNotificationsFetchRequestAction(searchKey, fetchNextPage));
        try {
            const searchResponse = await NotificationsApi.search({
                producerCodes: producerCode ? [producerCode] : [],
                readStatus: readStatus,
                includeExpired: readStatus === ReadStatus.Unread ? false : true,
                page: fetchNextPage ? searchResult.lastFetchedPage + 1 : 1,
                pageSize: PAGE_SIZE,
            });
            dispatch(createSearchNotificationsFetchSuccessAction(searchKey, searchResponse));
        } catch (error: any) {
            logger.error(error.message, error);
            dispatch(createSearchNotificationsFetchFailureAction(searchKey));
        }
    };
};

export const doMarkAsReadAll = (producerCode: Nullable<string>): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
        state,
        { logger, translate },
    ) => {
        const { notification: { isDismissingAll } } = state();
        if (isDismissingAll) {
            return;
        }

        dispatch(createTrackUserNotificationReadFetchRequestAction());
        try {
            await NotificationTrackingsApi.putMarkAsRead({
                allForRequester: true,
                producerCodes: producerCode ? [producerCode] : [],
            });
            dispatch(createTrackUserNotificationReadFetchSuccessAction());
        } catch (error: any) {
            logger.error(error);
            toastError(translate('my-notification:dismissedErrorAlertMessage'));
            dispatch(createTrackUserNotificationReadFetchFailureAction());
        }
    };
};

export const internalMarkAsRead = (notificationId: number): ThunkResult => {
    return async (
        dispatch: Dispatch<ActionType>,
    ) => {
        dispatch(createInternalMarkAsReadAction(notificationId));
    };
};
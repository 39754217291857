import React from 'react';
import { useApplicationContext } from 'components/hooks/UseApplicationContext';
import { EmptyLinePlaceholder } from 'components/common/EmptyLinePlaceholder';
import { clipPaginateList } from 'components/pages/search/clip-list';
import {
    SeeMoreLinkOnIc,
    SeeMoreLinkCompany,
} from 'components/pages/search/SeeMoreLink';
import { SourceInfosSearchItemCompactList } from 'components/pages/myServicesPage/components/search/SourceInfosSearchItemCompactList';
import { useSearchItemResults } from 'components/hooks/search/useSearchItemResults';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store';
import { FeatureTracked } from 'models/tracking';

type SearchCompanyResultsProps = {
    limit?: number;
    forceEmptyMessage?: boolean;
    isFullPage?: boolean;
};

const NotFound = () => {
    const { t: translate } = useTranslation('service-list');
    return (
        <p className="h6 text-secondary fw-normal">
            {translate('service-list:companyNotFound')}
        </p>
    );
};

const SeeMoreOnFullPage: React.FC = () => {
    const isInternal = useSelector(
        (state: AppState) => state.authentification.user?.IsSgGroup ?? false,
    );
    if (isInternal) {
        return <SeeMoreLinkOnIc />;
    }
    return <></>;
};
export const SearchCompanyResults: React.FC<SearchCompanyResultsProps> = ({
    limit,
    forceEmptyMessage,
    isFullPage,
}) => {
    const { company: { data, state } } = useSearchItemResults();
    const { t: translate } = useTranslation('service-list');
    const { canSearchPersonsAndCompanies } = useApplicationContext();
    const { clipped, total, hasMore } = clipPaginateList(data, limit);
    const isLoading = state === 'isFetching' || state === 'neverFetched';
    
    if (!canSearchPersonsAndCompanies) {
        return <></>;
    }
    if (!forceEmptyMessage && !isLoading && total === 0) {
        return <></>;
    }
    return (
        <div className="mt-5">
            <h4 className="h6 text-secondary py-2 mb-2">
                {translate('companyResults')}
            </h4>
            {isLoading ? <EmptyLinePlaceholder /> : null}
            {!isLoading && total > 0 ? (
                <SourceInfosSearchItemCompactList
                    feature={FeatureTracked.OpenCompany}
                    data={clipped}
                    useIcon="business"
                />
            ) : null}
            {!isLoading && hasMore ? (
                isFullPage ? (
                    <SeeMoreOnFullPage />
                ) : (
                    <SeeMoreLinkCompany total={total} />
                )
            ) : null}
            {!isLoading && total === 0 ? <NotFound /> : null}
        </div>
    );
};
